import { UUID } from 'angular2-uuid';
import {
  ApplicationTypes,
  ApprovalNotificationType,
  ApprovalType,
  FileType,
  ReportType,
  PasswordType,
  SuccessStatus,
  Status
} from '../shared/enums';
import { OAuthIdent } from './OAuthIdent';
import { Client } from './Client';

export class Approver {
  approverId: UUID;
  approvalType: ApprovalType;
  emails: string;
  externalId: string;
  appType?: ApplicationTypes;
  notificationType: ApprovalNotificationType;
  // if a user is using an external application, we need to save that OAuth info
  externalOauthInfo: OAuthIdent;
  order: number;
  clientId: UUID;
  fileType: FileType;
  reportTypes: ReportType;
  status: Status;
}

export class ApplicationLoadingStatus {
  [ApplicationTypes.Harvest]?: boolean | SuccessStatus;
  [ApplicationTypes.Toggl]?: boolean | SuccessStatus;
  [ApplicationTypes.ADP]?: boolean | SuccessStatus;
}

export class ApplicationAuthStatus {
  [ApplicationTypes.Harvest]?: boolean;
  [ApplicationTypes.Toggl]?: boolean;
  [ApplicationTypes.ADP]?: boolean;
}

export class InitiateClientApproval {
  client: Client;
  initiateImmediately = true;
  selectedDate: Date | string;
  initiationDate: Date | string;
  timeEntryIds: string[];
  userId: string;
}

export class PasswordAuthForm {
  provider: string;
  email: string;
  password: string;
  passwordType: PasswordType;
  billComTeamProvider: boolean;
}
